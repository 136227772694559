html,
body {
  overflow-x: hidden;
  max-width: 100%;
}

* {
  font-family: "Open sans", sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.desktop {
}
.mobile {
  display: none;
}

@media all and (max-width: 920px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: initial;
  }
}
